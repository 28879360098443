@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.inputFields {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  &>div>fieldset>ul>li>span>label>span>svg {
    margin-left: 1px;
    scale: 1.15;
  }
}

.recaptchaStyle {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.radioButtons {
  margin-top: 22px;

  &>label {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.2px;
    padding-bottom: 11px;
  }
}

.addressWrapper {
  margin: 40px 0;
}

.signupRadio {
  &>label>span:first-of-type {
    margin-top: -2px;
  }
}